var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all-book-topics-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[(_vm.routeName == 'Book' || _vm.selectedRoom)?_c('intract-smart-list',{ref:"topics-smart-list",attrs:{"endpoint":_vm.allTopicsEndpoint,"search":_vm.searchTopic},scopedSlots:_vm._u([{key:"list-item",fn:function({ item: topic }){return [_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                name: 'All'+ _vm.routeName +'TopicItems',
                params: { topicId: topic.id, topicTitle: topic.title },
              })}}},[_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_c('v-list-item-title',{staticClass:"text-wrap font-weight-medium"},[_vm._v(_vm._s(topic.title)+" ")]),(!topic.is_draft && topic.published_by)?_c('v-list-item-subtitle',{staticClass:"text--grey"},[_c('small',[_vm._v(_vm._s(topic.published_by.full_name))])]):_vm._e()],1),(topic.is_draft)?_c('v-list-item-icon',{staticClass:"align-self-center"},[(topic.scheduled)?_c('v-chip',{attrs:{"small":"","color":"orange"}},[_vm._v("Scheduled")]):_c('v-chip',{attrs:{"small":"","color":"primary","outlined":""}},[_vm._v("Draft")])],1):_c('v-list-item-icon',{staticClass:"align-self-center"},[(topic.date_published)?_c('span',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.moment(topic.date_published).format("Do MMM"))+" ")]):_vm._e()]),(_vm.canManage)?_c('v-list-item-icon',{staticClass:"align-self-center"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(topic.is_draft)?_c('v-list-item',{on:{"click":function($event){_vm.showPublishDialog = true;
                      _vm.toBePublishedTopic = topic;}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-publish")]),_c('v-list-item-title',[_vm._v("Publish")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.editTopic(topic)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")]),_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){_vm.toBeDeletedTopic = topic;
                      _vm.showDeleteDialog = true;}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")]),_c('v-list-item-title',[_vm._v("Delete")])],1),(_vm.currentUser.is_support_account)?_c('v-list-item',{attrs:{"disabled":!topic.subject.ref_no},on:{"click":function($event){_vm.qrSheet.visible = true;
                      _vm.generateQRCode(topic.subject.ref_no, topic.title);}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-qrcode")]),_c('v-list-item-title',[_vm._v("QR Code")])],1):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-divider',{staticClass:"mx-4"})]}}],null,false,3443830274)}):_vm._e()],1)],1),_c('v-fab-transition',[(!_vm.isLoading && !_vm.currentUser.is_student)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.createEditSheet.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('intract-create-edit-sheet',{attrs:{"persistent":"","title":(_vm.createEditSheet.editId ? 'Edit ' : 'Create ') + this.featureName + ' Topic',"description":'A '+ this.featureName + ' Topic can consist of various learning resources for your students',"visible":_vm.createEditSheet.visible,"fields":_vm.formFields,"endpoint":_vm.endpoints.bookTopics,"edit-id":_vm.createEditSheet.editId,"data-object":_vm.createEditSheet.obj,"create-success-message":_vm.createEditSheet.createSuccessMessage},on:{"close":function($event){_vm.createEditSheet.visible = false;
      _vm.createEditSheet.editId = null;},"objectCreated":_vm.topicCreated,"updateObject":(obj) => {
      _vm.createEditSheet.obj = obj;
      _vm.createEditSheet.obj.room = _vm.selectedRoom ? _vm.selectedRoom.id : null
    }},scopedSlots:_vm._u([(!_vm.createEditSheet.obj.scheduled)?{key:"after-submit-button",fn:function(){return [_c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","outlined":"","block":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Schedule for Later ")])]}}],null,false,4263276525),model:{value:(_vm.scheduleDialog.visible),callback:function ($$v) {_vm.$set(_vm.scheduleDialog, "visible", $$v)},expression:"scheduleDialog.visible"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Schedule for later "),_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){_vm.scheduleDialog.visible = false;
                _vm.scheduleDialog.datetime = null;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-text-field',{staticClass:"mt-4 mb-0",attrs:{"hide-details":"auto","dense":"","outlined":"","type":"datetime-local","label":"Schedule Date & Time"},model:{value:(_vm.scheduleDialog.datetime),callback:function ($$v) {_vm.$set(_vm.scheduleDialog, "datetime", $$v)},expression:"scheduleDialog.datetime"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","block":"","disabled":!_vm.scheduleDialog.datetime},on:{"click":function($event){_vm.createEditSheet.obj.scheduled = _vm.scheduleDialog.datetime;
                _vm.scheduleDialog.visible = false;
                _vm.scheduleDialog.datetime = null;}}},[_vm._v(" Schedule ")]),_c('v-spacer')],1)],1)],1)]},proxy:true}:null,{key:"custom-field--scheduled",fn:function({ obj }){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" Scheduled for "),_c('strong',{staticClass:"orange--text"},[_vm._v(_vm._s(_vm.moment(obj.scheduled).format("LLL")))])]),_c('v-list-item-subtitle',[_vm._v(" Will be published at the specified time. ")])],1),_c('v-list-item-action',[_c('v-icon',{on:{"click":function($event){obj.scheduled = null}}},[_vm._v("mdi-close")])],1)],1)]}}],null,true)}),_c('confirmation-dialog',{attrs:{"title":"Are you sure you want to delete this topic?","description":"Deleting this topic will also delete all the items inside it. This action cannot be undone.","visible":_vm.showDeleteDialog,"delete-dialog":""},on:{"successCallback":function($event){return _vm.deleteTopic(_vm.toBeDeletedTopic)},"failureCallback":function($event){_vm.toBeDeletedTopic = null;
      _vm.showDeleteDialog = false;}}}),_c('confirmation-dialog',{attrs:{"title":"Are you sure you want to publish this topic?","description":"This will notify all the students from this classroom that the topic has been published. Note that this will not publish internal items automatically.","visible":_vm.showPublishDialog},on:{"successCallback":function($event){return _vm.publishTopic(_vm.toBePublishedTopic)},"failureCallback":function($event){_vm.toBePublishedTopic = null;
      _vm.showPublishDialog = false;}}}),(_vm.currentUser.is_support_account)?_c('intract-bottom-sheet',{attrs:{"title":"QR Code","visible":_vm.qrSheet.visible},on:{"close":function($event){_vm.qrSheet.visible = false;
      _vm.qrSheet.value = null;}}},[_c('div',{staticClass:"text-center mt-4"},[_c('qrcode-vue',{staticClass:"mb-2",attrs:{"value":_vm.qrSheet.value,"size":"300","level":"H","id":"qr-code-canvas"}}),_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.downloadQRCode}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Download QR Code ")],1)],1)]):_vm._e(),(!_vm.currentUser.is_student)?_c('choose-room-dialog',{attrs:{"visible":_vm.showRoomDialog},on:{"roomSelected":(r) => {
        if (_vm.currentUser.is_admin || (_vm.currentUser.is_faculty && r.class_teachers && r.class_teachers.map(ct => ct.id).includes(_vm.currentUser.id))){
          _vm.selectedRoom = r;
          _vm.showRoomDialog = false;
        }
        else{
          this.showSnackbar({ title: 'Cannot view/create activity sheets for this classroom!', type: 'error' });
          return;
        }
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }